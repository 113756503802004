
import { MenuComponent, ScrollComponent } from "@/assets/ts/components";
import ModuleType from "@/core/constants/ModuleType";
import { IModule } from "@/core/data/module";
import { SearchModuleFilter } from "@/core/filterData/searchModuleFilter";
import ModuleService from "@/core/services/ModuleService";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/store/useAuthStore";
import UserRoleService from "@/core/services/UserRoleService";
export default defineComponent({
  name: "module-list",
  setup(props) {
    const route = useRoute();
    const isTeacher = ref<boolean>(false);
    const authStore = useAuthStore();
    const scienceModules = ref<Array<IModule>>([]);
    const naturalModules = ref<Array<IModule>>([]);
    const scrollElRef = ref<null | HTMLElement>(null);
    const initModules = () => {
      var searchScienceModuleFilter: SearchModuleFilter = {
        page: 1,
        size: 10,
        type: ModuleType.SCIENCE,
      };
      var searchNaturalModuleFilter: SearchModuleFilter = {
        page: 1,
        size: 10,
        type: ModuleType.NATURAL,
      };

      ModuleService.getModules(searchScienceModuleFilter).then((response) => {
        scienceModules.value = response.data.data;
      });

      ModuleService.getModules(searchNaturalModuleFilter).then((response) => {
        naturalModules.value = response.data.data;
      });
    };

    const getTeacher = () => {
      UserRoleService.isTeacher().then((response) => {
        isTeacher.value = response.data;
      });
    };

    onMounted(() => {
      initModules();
      getTeacher();
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    watch(
      () => authStore.isModuleDataChange,
      (newValue, oldValue) => {
        initModules();
        authStore.setModuleDataChange(false);
      }
    );

    return {
      initModules,
      scienceModules,
      naturalModules,
      isTeacher,
    };
  },
});
