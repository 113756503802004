
import { defineComponent, ref } from "vue";
import TopicList from "@/views/studio-lab/components/menu/TopicList.vue";
import ModuleList from "@/views/studio-lab/components/menu/ModuleList.vue";
import GroupList from "@/views/studio-lab/components/menu/GroupList.vue";
import GroupActivityList from "@/views/studio-lab/components/menu/GroupActivityList.vue";
import {
  minimizedAsideSecondary,
  asideSecondaryDisplay,
  minimizationEnabled,
} from "@/core/helpers/config";

export default defineComponent({
  name: "kt-aside-secondary",
  components: {
    TopicList,
    ModuleList,
    GroupList,
    GroupActivityList,
  },
  setup() {
    const topicModalRef = ref();

    const createTopic = () => {
      topicModalRef.value.showModal();
    };

    return {
      minimizedAsideSecondary,
      asideSecondaryDisplay,
      minimizationEnabled,
      topicModalRef,
      createTopic,
    };
  },
});
