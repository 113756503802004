
import { computed, defineComponent, onMounted, ref } from "vue";
// import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useAuthStore } from "@/store/useAuthStore";
import UserRoleService from "@/core/services/UserRoleService";

export default defineComponent({
  name: "kt-aside-primary",
  components: {
    // KTNotificationsMenu,
    KTUserMenu,
  },
  setup(props, ctx) {
    const authStore = useAuthStore();
    const isTeacher = ref<boolean>(false);
    const currentUser = computed(() => {
      return authStore.currentUser;
    });

    const getTeacher = () => {
      UserRoleService.isTeacher().then((response) => {
        isTeacher.value = response.data;
      });
    };

    onMounted(() => {
      getTeacher();
    });

    return { currentUser, isTeacher };
  },
});
