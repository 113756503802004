
import { defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import { ITopic } from "@/core/data/topic";
import TopicService from "@/core/services/TopicService";
import { useAuthStore } from "@/store/useAuthStore";
import TopicStatus from "@/core/constants/TopicStatus";
import { SearchTopicFilter } from "@/core/filterData/searchTopicFilter";
import moment from "moment";

export default defineComponent({
  name: "topic-list",
  setup(props) {
    const { t, te } = useI18n();
    const route = useRoute();
    const authStore = useAuthStore();
    const scrollElRef = ref<null | HTMLElement>(null);

    const topicModalRef = ref();
    const todayTopics = ref<Array<ITopic>>([]);
    const recentTopics = ref<Array<ITopic>>([]);
    const activityStatus = ref("");


    const initTopics = () => {
      var searchTodayTopicFilter: SearchTopicFilter = {
        page: 1,
        size: 10,
        status: TopicStatus.ON_GOING,
      };
      var searchRecentTopicFilter: SearchTopicFilter = {
        page: 1,
        size: 10,
        status: TopicStatus.RECENT,
      };

      TopicService.getTopics(searchTodayTopicFilter).then((response) => {
        todayTopics.value = response.data.data;
      });

      TopicService.getTopics(searchRecentTopicFilter).then((response) => {
        recentTopics.value = response.data.data;
      });
    };

    const getActivityStatus = (expireDate) => {
      return moment().isAfter(
        moment(expireDate)
      )
        ? "Expired"
        : "On Going";
    };

    const createTopic = () => {
      topicModalRef.value.showModal();
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    watch(
      () => authStore.isTopicDataChange,
      (newValue, oldValue) => {
        initTopics();
        authStore.setTopicDataChange(false);
      }
    );

    onMounted(() => {
      initTopics();
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    return {
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
      topicModalRef,
      createTopic,
      initTopics,
      todayTopics,
      recentTopics,
      activityStatus,
      getActivityStatus
    };
  },
});
