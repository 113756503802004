import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_aside",
  class: "aside aside-extended bg-red",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "aside",
  "data-kt-drawer-activate": "{default: true, lg: false}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "auto",
  "data-kt-drawer-direction": "start",
  "data-kt-drawer-toggle": "#kt_aside_toggle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTAsidePrimary = _resolveComponent("KTAsidePrimary")!
  const _component_KTAsideSecondary = _resolveComponent("KTAsideSecondary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_KTAsidePrimary),
    _createVNode(_component_KTAsideSecondary)
  ]))
}