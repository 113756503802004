
import { defineComponent, onMounted, ref } from "vue";
import UserRoleService from "@/core/services/UserRoleService";

export default defineComponent({
  name: "topbar",
  components: {},
  setup() {
    const isTeacher = ref<boolean>(false);
    const getTeacher = () => {
      UserRoleService.isTeacher().then((response) => {
        isTeacher.value = response.data;
      });
    };

    onMounted(() => {
      getTeacher();
    });

    return {
      isTeacher
    }
  }
});
